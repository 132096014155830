body {
  background-image: url('bg.png');
  background-size: cover;
  background-position: center;
}

.App {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  background-color: rgba(137, 136, 135, .5);

}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  margin: .5vmin 0 .5vmin 1.25vmin;

}

h4.app-title {
  font-size: 1.15rem;
  font-weight: 600;
  margin: 0 1.25vmin 0 0;
  text-align: right;

}

.empty-container {


  background-image: url('empty-gradient.jpg');
  background-size: cover;
  background-position: center;
  aspect-ratio: 16 / 9 !important;
  width: 100% !important;
  border-width: 0 !important;

}

.video-container {
  display: flex;
  justify-content: center;

}



.video-iframe {
  aspect-ratio: 16 / 9 !important;
  width: 100% !important;
  border-width: 0 !important;
  overflow: hidden;
}

@media (min-width: 968px) {
  .App-logo {
    height: 7vmin;

  }


}

.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}